<template>
  <form @submit.prevent="$emit('submit', searchQuery)">
    <label
      class="flex space-x-20 items-center md:mt-25"
      for="search"
    >
      <input
        id="search"
        v-model="searchQuery"
        type="search"
        name="search"
        :placeholder="`${$t('search.collection')}...`"
        class="flex w-full border-b border-primary-300 placeholder-primary-300 text-primary-700 focus:border-primary-700 transition outline-none"
      >
      <button
        type="submit"
        class="font-serif text-xl underline hover:text-primary-700 transition mt-20 sm:mt-0"
      >
        {{ $t('general.search') }}
      </button>
    </label>
  </form>
</template>

<script setup>
import { ref } from '#imports';

// Emits
defineEmits(['submit']);

const searchQuery = ref('');
</script>
